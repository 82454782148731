import { _ClearTokens, _GetAccessToken, _GetRefreshToken, _GetUserData, _StoreTokens } from '@netfront/gelada-react-shared';
import { ToastContainer } from 'react-toastify';
import { format } from 'date-fns';
import Head from 'next/head';
import Router from 'next/router';
import React from 'react';
import { Icons } from 'components/Icons/Icons';
import * as gtagCommand from 'lib/gtag';
import gtag from 'ga-gtag';

import 'animate.css';
import 'index.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { getExpiry, isTokenExpired } from 'utils/tokenUtils';

Router.events.on('routeChangeComplete', (url) => gtagCommand.pageview(url));

function App({ Component, pageProps }) {
  const accessToken = _GetAccessToken();
  const refreshToken = _GetRefreshToken();

  React.useEffect(() => {
    if (accessToken || refreshToken) {
      if (isTokenExpired(refreshToken) || isTokenExpired(accessToken)) {
        _ClearTokens();
        window.location.href = '/';
        return;
      }
    }
  }, []);

  React.useEffect(() => {
    if (_GetUserData()) {
      gtag('set', { user_id: _GetUserData().id });
    }

    if (Router.query.auth) {
      if (!_GetAccessToken()) {
        _StoreTokens(`bearer ${String(Router.query.auth)}`, `bearer ${String(Router.query.auth)}`);
        Router.reload();
      } else {
        Router.push(window.location.href.split('?')[0]);
      }
    }

    window.onclick = function (e) {
      const a = e.target.closest('a');
      if (!a) return;
      if (a.href.indexOf(window.location.origin) !== 0) return;
      if (a.target === '_blank') return;
      e.preventDefault();

      const as = a.href.replace(window.location.origin, '');
      const currentLocation = window.location.pathname;

      if (as === '/calendar/entry' && currentLocation.indexOf('/module') === 0) {
        Router.push({ pathname: '/calendar/entry', query: { date: format(new Date(), 'yyyy-MM-dd'), returnhref: currentLocation } });
        return;
      }

      if (a.classList.contains('NextLink')) return;

      let href = as;
      if (href.match(/public\/.*/)) {
        href = '/public/[pageUrl]';
      } else if (href.match(/module\/.*\/.*/)) {
        href = '/module/[stepUrl]/[pageUrl]';
      }

      Router.push(href, as);
    };

    Router.events.on('routeChangeComplete', () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    });
  }, []);

  return (
    <>
      <Head>
        {process.env.REACT_APP_KANZI_APP_ID && (
          <script
            id="kanzi"
            data-defaultlanguage="en"
            data-apikey={process.env.REACT_APP_KANZI_APP_ID}
            src="https://plugin.kanzi.io/lib/kanzi.js"
          ></script>
        )}
        <script>
          {` function redirect(href){
            window.location.href=href;
          }`}
        </script>
      </Head>
      {/* <input
        type="button"
        value="PDF"
        onClick={() => {
          fetch(`https://localhost:44346/api/Pdf/generateFromUrl?projectId=${process.env.REACT_APP_PROJECT_ID}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Access-Control-Allow-Origin':'*',
              'Accept':'application/json',
              'Content-Type':'application/json',
              'Authorization':_GetAccessToken()
            },
            body:JSON.stringify({s3Key: "string", url: "https://dvkwklytlcay3.cloudfront.net/module/module-1/introduction"}) 
          }).then(c=>{
            console.log(c)
          })
          .catch(e=>console.error(e));
        }}
      /> */}
      <div id="root">
        <Component {...pageProps} />
      </div>
      <Icons />
      <ToastContainer />
    </>
  );
}

export default App;
